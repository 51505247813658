
import Base from '@/mixins/Base.vue';
import { defineComponent } from 'vue';

export default defineComponent({
    mixins: [Base],
    data() {
        return {
            item: {
                name: '',
            },
        };
    },
    methods: {
        createItem(): void {
            this.post('platforms', {
                ...this.item,
            }).then(() => {
                this.$router.push({ name: 'platforms.list' });
                this.$emit('reload');
            });
        },
    },
});
